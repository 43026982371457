/** @jsxImportSource @emotion/react */

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { SEOContent } from "./index.style";

const SEO = () => {
  const [showmore, setShowmore] = useState<boolean>(false);
  const { t } = useTranslation("footerSEO");

  return (
    <div css={SEOContent.wrapper}>
      <div className={showmore ? "" : "blue"}>
        <h1 style={{ marginBottom: 12 }}>{t("seo_title_main")}</h1>
        <h2>{t("seo_subtitle_main")}</h2>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_intro1")}</p>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_intro2")}</p>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_intro3")}</p>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_intro4")}</p>
        <p>{t("seo_paragraph_intro5")}</p>
        <h2 style={{ marginTop: 10 }}>{t("seo_subtitle_whyChoose")}</h2>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_whyChoose1")}</p>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_whyChoose2")}</p>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_whyChoose3")}</p>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_whyChoose4")}</p>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_whyChoose5")}</p>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_whyChoose6")}</p>
        <h2 style={{ marginTop: 10 }}>{t("seo_subtitle_gamingVariety")}</h2>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_gamingVariety1")}</p>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_gamingVariety2")}</p>
        <p>{t("seo_paragraph_gamingVariety3")}</p>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_gamingVariety4")}</p>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_gamingVariety5")}</p>
        <h2 style={{ marginTop: 10 }}>{t("seo_subtitle_conclusion")}</h2>
        <p style={{ marginBottom: 6 }}>{t("seo_paragraph_conclusion")}</p>
      </div>
      {!showmore && (
        <div className="showmore" onClick={() => setShowmore(true)}>
          {t("seo_button_showMore")}
        </div>
      )}
    </div>
  );
};

export default SEO;
