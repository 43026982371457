import { AnnouncementApi } from "api/announcement";
import { SeoType } from "api/announcement/type";
import { utilChangeSeoId } from "helpers/utilChangeSeoId";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

type Props = {
  title?: string | undefined;
};

function HelmetCustom(props: Props) {
  const [data, setData] = useState<SeoType[]>([]);

  const seo_id = utilChangeSeoId(window.location.pathname) || "";

  const fetDataSEO = async () => {
    try {
      const res = await AnnouncementApi.getSeo({ seo_id: seo_id });
      setData(res.data.data);
    } catch (error) {
      // alert.showAlert(error + "", "error");
      console.log("error", error + "");
    }
  };
  useEffect(() => {
    if (!!seo_id.length) {
      fetDataSEO();
    }
  }, [seo_id]);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data[0]?.page_title || ""}</title>
      <meta name="description" content={data[0]?.meta_descriptions || ""} />
      <meta name="keywords" content={data[0]?.meta_keywords || ""} />
      <meta property="og:title" content={data[0]?.meta_header || ""} />
      <meta
        property="og:description"
        content={data[0]?.meta_descriptions || ""}
      />

      <link
        rel="icon"
        type="image/png"
        href="https://baji568.com/favicon.ico"
      />
    </Helmet>
  );
}

export default HelmetCustom;
