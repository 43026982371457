/** @jsxImportSource @emotion/react */

import BgHeaderUserLayout from "assets/temporary/images/bg-user-layout.png";
import Footer from "components/Common/Footer";
import { Header } from "components/Desktop/ui/Header";
import UserMenuSidebar from "components/Desktop/ui/UserMenuSidebar";
import { Outlet } from "react-router";

import { UserLayoutStyle } from "./index.style";

const UserLayout = () => {
  return (
    <div css={UserLayoutStyle.self}>
      {/* <HelmetCustom title={"User"} /> */}
      <Header />
      <div css={UserLayoutStyle.contentWrapper(BgHeaderUserLayout)}>
        <UserMenuSidebar />
        <main css={UserLayoutStyle.mainContent}>
          <Outlet />
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default UserLayout;
