import { PATH } from "contants/path";

export const utilChangeSeoId = (pathName: string) => {
  const seoPath = {
    [PATH.Home]: {
      seo_id: "1",
    },
    [PATH.Promotion]: {
      seo_id: "8",
    },
    [PATH.referral]: {
      seo_id: "22",
    },
    [PATH.Download]: {
      seo_id: "9",
    },
    [PATH.Brand]: {
      seo_id: "31",
    },

    [PATH.contactUs]: {
      seo_id: "32",
    },
    [PATH.infoVip]: {
      seo_id: "29",
    },
    [PATH.affiliatePage]: {
      seo_id: "7",
    },
    [PATH.User.personalDetails]: {
      seo_id: "18",
    },
    [PATH.User.TransactionHistory]: {
      seo_id: "17",
    },
    [PATH.User.deposit]: {
      seo_id: "15",
    },
    [PATH.User.withdraw]: {
      seo_id: "16",
    },
    [PATH.User.Referral]: {
      seo_id: "21",
    },
    [PATH.User.Setting]: {
      seo_id: "24",
    },
    [PATH.User.Announcement]: {
      seo_id: "30",
    },
    [PATH.User.myVip]: {
      seo_id: "29",
    },
    [PATH.User.BettingRecord]: {
      seo_id: "20",
    },
    [PATH.TermsAndConditions]: {
      seo_id: "33",
    },
    [PATH.User.memberDocuments]: {
      seo_id: "26",
    },
    [PATH.User.MemberReport]: {
      seo_id: "27",
    },
    "/live": {
      seo_id: "4",
    },
    "/sports": {
      seo_id: "4",
    },
    "/slots/PP": {
      seo_id: "43",
    },
    "/sports/JILI": {
      seo_id: "44",
    },
    "/sports/KA": {
      seo_id: "45",
    },
    "/sports/HB": {
      seo_id: "46",
    },
    "/sports/SG": {
      seo_id: "47",
    },
    "/sports/PGS2": {
      seo_id: "48",
    },
    "/sports/ACEW": {
      seo_id: "49",
    },
    "/sports/FC": {
      seo_id: "50",
    },
    "/fishing/SG": {
      seo_id: "54",
    },
    "/fishing/JILI": {
      seo_id: "55",
    },
    "/fishing/ACEW": {
      seo_id: "56",
    },
    "/board-games/ACEW": {
      seo_id: "57",
    },
    "/board-games/JILI": {
      seo_id: "58",
    },
    "/board-games/KA": {
      seo_id: "59",
    },
    "/board-games/KM": {
      seo_id: "60",
    },
    "/board-games/SG": {
      seo_id: "61",
    },
    [PATH.User.profile]: {
      seo_id: "14",
    },
    [PATH.User.UpdateInfo]: {
      seo_id: "19",
    },
    [PATH.User.changePassword]: {
      seo_id: "13",
    },
    "*": {
      seo_id: "34",
    },
  };

  return seoPath?.[pathName]?.seo_id || "34";
};
