/** @jsxImportSource @emotion/react */
import "react-phone-number-input/style.css";

import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Form, notification } from "antd";
import { AuthApi } from "api/auth";
import { ForgotPasswordFormType, RequestOTPType } from "api/auth/type";
import backgroundLogin from "assets/temporary/images/list_bg_2.jpg";
import InputCustom from "components/Common/InputCustom";
import { ModalCustom } from "components/Common/ModalCustom";
import { REGEX_USERNAME } from "contants/regex";
import { ResponseCode } from "contants/response";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { i18n } from "i18n";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { selectIsLoading } from "store/auth";
import { selectOpenModal, setOpenModal } from "store/modal";
import { StatusNotification } from "types";
import { ApiResponse } from "types/api";
import * as yup from "yup";

import { LogoAuth } from "../LogoAuth";
import { RecoveryModalStyle } from "./index.style";

export const RecoveryModal = () => {
  const { t } = useTranslation(["login", "placeholder", "button", "validate"]);

  const dispatch = useAppDispatch();

  const openModal = useAppSelector(selectOpenModal);
  const isLoading = useAppSelector(selectIsLoading);

  const [dataPassword, setDataPassword] = useState<string>("");

  const schema = yup
    .object({
      username: yup
        .string()
        .trim()
        .matches(REGEX_USERNAME, i18n.t("validate:UsernameRequired"))
        .required(),
      mobile: yup.string().trim().required(i18n.t("validate:FieldRequired")),
      otp: yup.string().trim().required(i18n.t("validate:OTPValid")),
      // email: yup
      //   .string()
      //   .trim()
      //   .email(i18n.t("validate:EmailRequired"))
      //   .required(i18n.t("validate:FieldRequired")),
    })
    .required();

  const {
    handleSubmit,
    control,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm<ForgotPasswordFormType>({
    resolver: yupResolver<any>(schema),
    mode: "all",
  });

  const isMobile = watch("mobile");
  const isUsername = watch("username");

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (status: StatusNotification, message: string) => {
    api[status]({
      message,
      description: "",
    });
  };

  const getOtpCode = async () => {
    const data = getValues(["username", "mobile"]);

    const dataRequest: RequestOTPType = {
      username: data[0].toLocaleLowerCase(),
      mobile: data[1].replace("+", ""),
      type: 2,
    };
    const res = await AuthApi.requestOTP(dataRequest);
    const resData = res.data;
    openNotification(
      resData.status === ResponseCode.SUCCESS ? "success" : "error",
      resData.msg
    );
  };

  const onSubmit = async (data: ForgotPasswordFormType) => {
    const datForm = {
      ...data,
      username: data.username.toLocaleLowerCase(),
      mobile: data.mobile.replace("+", ""),
    };
    const res = await AuthApi.forgotPassword(datForm.username, datForm);

    const dataResult = res.data as ApiResponse;

    if (dataResult.status !== ResponseCode.SUCCESS) {
      openNotification("error", dataResult.msg);
      return;
    }

    openNotification("success", dataResult.msg);
    setDataPassword(dataResult.msg);
    reset();
  };

  const handleCloseModal = () => {
    dispatch(setOpenModal(""));
  };

  const checkButtonGetOtp = () => {
    if (!isMobile) {
      // if (!isMobile || (isMobile && !isValidPhoneNumber(isMobile))) {
      return true;
    }
    if (!isUsername || (isUsername && errors.username?.message)) {
      return true;
    }
    return false;
  };

  return (
    <ModalCustom
      isOpenModal={openModal.includes("recoveryPassword")}
      handleCloseModal={handleCloseModal}
      titleModal={t("Forget")}
      headerBackground={backgroundLogin}
      heightBackground={75}
      bodyModal={
        <>
          {contextHolder}
          <LogoAuth />

          <Form
            css={RecoveryModalStyle.formContainer}
            onSubmitCapture={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <InputCustom
              disabled={isLoading}
              placeholder={t("placeholder:Username")}
              name={"username"}
              control={control}
              errors={errors.username?.message}
              margin_bottom="8px"
              validate_status={errors.username ? "error" : "success"}
            />

            {/* <InputCustom
              disabled={isLoading}
              placeholder={t("placeholder:Email")}
              name={"email"}
              control={control}
              errors={errors.email?.message}
              margin_bottom="8px"
              validate_status={errors.email ? "error" : "success"}
            /> */}

            <InputCustom
              disabled={isLoading}
              placeholder={t("placeholder:PhoneNumber")}
              name={"mobile"}
              type="number"
              control={control}
              errors={errors.mobile?.message}
              margin_bottom="8px"
              validate_status={errors.mobile ? "error" : "success"}
            />

            <Form.Item
              style={{ marginBottom: "0px" }}
              help={errors.otp?.message}
              validateStatus={errors.otp ? "error" : "success"}
            >
              <Controller
                name={"otp"}
                control={control}
                render={({ field: { onChange, value } }) => {
                  const isValidOpt = checkButtonGetOtp();

                  return (
                    <div css={RecoveryModalStyle.optCode}>
                      <input
                        placeholder={t("placeholder:OTPcode")}
                        value={value || ""}
                        onChange={onChange}
                        disabled={isValidOpt}
                      />
                      <Button onClick={getOtpCode} disabled={isValidOpt}>
                        {t("button:GetOTP")}
                      </Button>
                    </div>
                  );
                }}
              />
            </Form.Item>

            {dataPassword && (
              <div css={RecoveryModalStyle.dataPassword}>
                <span>{dataPassword}</span>
              </div>
            )}
            <button
              css={[
                RecoveryModalStyle.buttonSubmit,
                RecoveryModalStyle.buttonActive,
              ]}
              disabled={isLoading}
              type="submit"
            >
              {t("button:Forgot")}
            </button>
            <span css={RecoveryModalStyle.login}>
              {t("login:Already")}{" "}
              <span onClick={() => dispatch(setOpenModal("signin"))}>
                {t("login:LOGIN")}
              </span>
            </span>
          </Form>
        </>
      }
    />
  );
};
