import axiosRequest from "api";
import { payloadHelper } from "helpers/payload-helper";

import {
  AnnouncementFormType,
  AnnouncementResponse,
  PayloadSeoType,
  SeoResponse,
} from "./type";

export const AnnouncementApi = {
  getAnnouncement: (payload: AnnouncementFormType) =>
    axiosRequest.post<AnnouncementResponse>(
      "/",
      payloadHelper("Announcement", null, payload)
    ),
  getSeo: (payloadSeo: PayloadSeoType) =>
    axiosRequest.post<SeoResponse>(
      "/",
      payloadHelper("GetSEO", null, payloadSeo)
    ),
};
