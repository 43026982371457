import "../i18n";

type affType = {
  player: string;
  deposit: string;
  cashout: string;
  winLoss: string;
  platformFee: string;
  bonus: string;
  paymentFee: string;
  commission: string;
};

export const dataAffiliate: affType[] = [
  {
    player: "A",
    deposit: "10000",
    cashout: "2000",
    winLoss: "8000",
    platformFee: "1440",
    bonus: "0",
    paymentFee: "350",
    commission: "1863",
  },
  {
    player: "B",
    deposit: "50000",
    cashout: "0",
    winLoss: "50000",
    platformFee: "9000",
    bonus: "0",
    paymentFee: "1750",
    commission: "11775",
  },
  {
    player: "C",
    deposit: "0",
    cashout: "0",
    winLoss: "0",
    platformFee: "0",
    bonus: "200",
    paymentFee: "0",
    commission: "-60",
  },
  {
    player: "D",
    deposit: "500",
    cashout: "0",
    winLoss: "500",
    platformFee: "90",
    bonus: "500",
    paymentFee: "17.5",
    commission: "-32.25",
  },
  {
    player: "E",
    deposit: "10000",
    cashout: "40000",
    winLoss: "-30000",
    platformFee: "-5400",
    bonus: "10000",
    paymentFee: "350",
    commission: "-10485",
  },
];
