/** @jsxImportSource @emotion/react */

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { UserApi } from "api/user";
import avatar from "assets/temporary/images/avatar_default.png";
import { PATH } from "contants/path";
import { ResponseCode } from "contants/response";
import { formatNumberWithCommas } from "helpers/formatNumber";
import { useAppSelector } from "hooks/app-hook";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { selectUsername } from "store/auth";
import { selectBalance, selectBalanceLoading, selectProfile } from "store/user";

import { HeaderAuthStyle } from "./style.index";

interface Props {
  visibleMenu: string;
  colorText: string;
}

interface turnoverType {
  achieve_amount: number;
  current_amount: number;
}

const HeaderAuth = ({ visibleMenu, colorText }: Props) => {
  const { t } = useTranslation(["header", "currency", "placeholder"]);
  const [isLoadingTurnover, setIsLoadingTurnover] = useState<boolean>(false);
  const [amountTurnover, setAmountTurnover] = useState<turnoverType>({
    achieve_amount: 0,
    current_amount: 0,
  });

  const profileUser = useAppSelector(selectProfile);
  const balanceUser = useAppSelector(selectBalance);
  const isLoading = useAppSelector(selectBalanceLoading);
  const navigate = useNavigate();

  const username = useAppSelector(selectUsername);

  const fetchDataTurnover = async () => {
    try {
      setIsLoadingTurnover(true);
      const res = await UserApi.playerTurnover(username);
      setIsLoadingTurnover(false);

      if (res.status === ResponseCode.SUCCESS) {
        setAmountTurnover(res.data);
      }
    } catch (error) {
      throw new Error(error as string);
    }
  };

  useEffect(() => {
    fetchDataTurnover();
  }, []);

  return (
    <div css={HeaderAuthStyle.wrapper}>
      <div css={HeaderAuthStyle.infoHeader}>
        <h4 css={HeaderAuthStyle.title(visibleMenu, colorText)}>
          {profileUser && profileUser.username}
        </h4>

        <div css={HeaderAuthStyle.points(visibleMenu, colorText)}>
          <p>
            {t("currency:BDT")}{" "}
            {isLoading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 12, color: "#298548" }}
                    spin
                  />
                }
              />
            ) : (
              <> {formatNumberWithCommas(balanceUser?.total || "0.00")}</>
            )}
          </p>
        </div>
      </div>
      <img
        src={avatar}
        alt=""
        css={HeaderAuthStyle.avatar}
        onClick={() => navigate(PATH.User.TransactionHistory)}
      />
      <div css={HeaderAuthStyle.turnover(visibleMenu, colorText)}>
        <span>{t("placeholder:Turnover")}</span>
        {isLoadingTurnover ? (
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 12, color: "#298548" }}
                spin
              />
            }
          />
        ) : (
          <div className="amount">
            <span>
              {formatNumberWithCommas(amountTurnover.achieve_amount || "0.00")}
            </span>
            <span>&nbsp;/&nbsp;</span>
            <span>
              {formatNumberWithCommas(amountTurnover.current_amount || "0.00")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderAuth;
