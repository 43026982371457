/** @jsxImportSource @emotion/react */

import { QRCode } from "antd";
import { AnnouncementApi } from "api/announcement";
import { ReactComponent as IconTelevison } from "assets/Common/icons/icon-television.svg";
import AndroidIcon from "assets/Desktop/icons/homepage/androidIcon.png";
import IosIcon from "assets/Desktop/icons/homepage/IosIcon.png";
import AppDownLoad from "assets/Desktop/images/homepage/appDownload.png";
import ImageNotification from "assets/Desktop/images/homepage/notification-notifi.png";
import SwiperBottomSwiper from "assets/Desktop/images/homepage/swiper-bottom.png";
import HelmetCustom from "components/Common/HelmetCustom";
import Container from "components/Desktop/base/Container";
import DetailAnnouncement from "components/Desktop/ui/DetailAnnouncement";
import GaugeItem from "components/Desktop/ui/GaugeItemHomepage";
import { LIST_ITEM_GAUGE } from "components/Desktop/ui/GaugeItemHomepage/list-item";
import ServiceItem from "components/Desktop/ui/ServiceItemHomepage";
import { LIST_ITEM_SERVICE } from "components/Desktop/ui/ServiceItemHomepage/list-item";
import { URL_DOWNLOAD_ANDROID, URL_DOWNLOAD_IOS } from "contants/common";
import { useAppDispatch, useAppSelector } from "hooks/app-hook";
import { useScrollTop } from "hooks/useScrollTop";
import {
  LegacyRef,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { selectAnnouncement } from "store/announcement";
import { getAnnouncement } from "store/announcement/announcement.thunk";
import { selectIsAuthenticated, selectUsername } from "store/auth";
import { setOpenModal } from "store/modal";
import { OpenDetailsType } from "types/announcement";

import Banner from "./Banner";
import { HomePageStyle } from "./index.style";
import OurPartners from "./OurPartners";
import SEO from "./SEO";
import { ArrowNextCustom, ArrowPrevCustom } from "./Swiper/ArrowCustom";
import CategorySwiper from "./Swiper/categorySwiper";
import { LIST_CATEGORY_SWIPER } from "./Swiper/categorySwiper/list-category";
import GameSwiper from "./Swiper/gamesSwiper";
import { LIST_GAMES_SWIPER } from "./Swiper/gamesSwiper/list-game";

const initStateDetails: OpenDetailsType = {
  visible: false,
  dataItem: undefined,
};

const HomePage = () => {
  useScrollTop();

  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuthenticated);

  const { t } = useTranslation("home");
  const username = useAppSelector(selectUsername);

  const [state, setState] = useState<OpenDetailsType>(initStateDetails);
  const dataAnnouncement = useAppSelector(selectAnnouncement);

  const sliderRefBelow: MutableRefObject<Slider | null | undefined> = useRef();
  const sliderRefAbove: MutableRefObject<Slider | null | undefined> = useRef();

  useEffect(() => {
    dispatch(getAnnouncement({ page: 1, size: 1 }));

    if (!isAuth) return;
    const hasVisitedBefore = localStorage.getItem("visible");

    if (hasVisitedBefore === "true") return;

    getData(1, 100, 1);
  }, [isAuth]);

  const getData = async (page: number, size: number, type?: number) => {
    const response = await AnnouncementApi.getAnnouncement({
      page,
      size,
      type,
    });

    const res = response.data;

    if (!res.data.length) return;

    localStorage.setItem("visible", "true");
    setState({
      visible: true,
      dataItem: res.data[0],
    });
  };

  const handleredirectLink = () => {
    if (!username) {
      dispatch(setOpenModal("signin"));
    } else {
      window.open("https://m.crichd.vip/web007");
    }
  };

  return (
    <div css={HomePageStyle.wrapper.index}>
      <HelmetCustom />
      <Banner />
      <section css={HomePageStyle.notification.index}>
        <Container>
          <div css={HomePageStyle.notification.item}>
            <img src={ImageNotification} alt="" />
            <div css={HomePageStyle.notification.text}>
              <span>
                {dataAnnouncement.map((item, index) => (
                  <div
                    key={index}
                    className="width-text"
                    dangerouslySetInnerHTML={{
                      __html: `${item.title}`,
                    }}
                  />
                ))}
              </span>
            </div>
          </div>
        </Container>
      </section>

      <div css={HomePageStyle.overview.index}>
        <Container>
          <div css={HomePageStyle.wrapper.title}>{t("Product")}</div>
          <div css={HomePageStyle.wrapper.describe}>{t("ContentProduct")}</div>
          <Slider
            css={HomePageStyle.overview.categorySwiper}
            slidesToShow={5}
            focusOnSelect={true}
            ref={sliderRefAbove as LegacyRef<Slider> | undefined}
          >
            {LIST_CATEGORY_SWIPER.map((item, index: number) => (
              <div
                key={item.key}
                onClick={() => {
                  sliderRefBelow.current?.slickGoTo(index);
                }}
              >
                <CategorySwiper key={item.key} {...item} />
              </div>
            ))}
          </Slider>

          <Slider
            css={HomePageStyle.overview.gamesSwiper}
            centerPadding="20px"
            centerMode={true}
            ref={sliderRefBelow as LegacyRef<Slider> | undefined}
            slidesToShow={3}
            nextArrow={
              <ArrowNextCustom
                sliderRefBelow={sliderRefBelow}
                sliderRefAbove={sliderRefAbove}
              />
            }
            prevArrow={
              <ArrowPrevCustom
                sliderRefBelow={sliderRefBelow}
                sliderRefAbove={sliderRefAbove}
              />
            }
            touchMove={false}
          >
            {LIST_GAMES_SWIPER.map((item) => (
              <GameSwiper key={item.key} {...item} />
            ))}
          </Slider>
          <div css={HomePageStyle.imgBottom}>
            <img src={SwiperBottomSwiper} alt="" />
          </div>
        </Container>
      </div>

      <section css={HomePageStyle.info.index}>
        <Container>
          <button css={HomePageStyle.info.btnLink} onClick={handleredirectLink}>
            <IconTelevison />
            {t("CricketLive")}
          </button>
          <div css={HomePageStyle.info.header.index}>
            <div css={HomePageStyle.wrapper.title}>{t("APP DOWNLOAD")}</div>
            <div css={HomePageStyle.wrapper.describe}>{t("TitleDownload")}</div>
          </div>
          <div css={HomePageStyle.info.bottom.index}>
            <div css={HomePageStyle.info.bottom.image}>
              <img src={AppDownLoad} alt="" />
            </div>
            <div css={HomePageStyle.info.bottom.content}>
              <div css={HomePageStyle.cardInner}>
                <h2>{t("TitleContentDownload")}</h2>
                <p>{t("ContentDownload")}</p>
                <div css={HomePageStyle.sectionQr}>
                  <div css={HomePageStyle.blockQr}>
                    <QRCode value={URL_DOWNLOAD_IOS} size={110} />
                    <div css={HomePageStyle.blockIconQr}>
                      <img src={IosIcon} alt="" />
                      <span>{t("Apple iOS")}</span>
                    </div>
                  </div>
                  <div css={HomePageStyle.blockQr}>
                    <QRCode value={URL_DOWNLOAD_ANDROID} size={110} />
                    <div css={HomePageStyle.blockIconQr}>
                      <img src={AndroidIcon} alt="" />
                      <span>{t("Android")}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <Container>
        <div css={HomePageStyle.service.header}>
          <div css={HomePageStyle.wrapper.title}>{t("Excellent Service")}</div>
          <div css={HomePageStyle.wrapper.describe}>{t("TitleExcellent")}</div>
        </div>
        <section
          css={[HomePageStyle.service.content, HomePageStyle.service.grid_4]}
        >
          {LIST_ITEM_GAUGE.map((item, index) => (
            <GaugeItem key={index} {...item} />
          ))}
        </section>

        <section
          css={[HomePageStyle.service.content, HomePageStyle.service.grid_2]}
        >
          {LIST_ITEM_SERVICE.map((item, index) => (
            <ServiceItem key={index} {...item} />
          ))}
        </section>
        <SEO />
        <OurPartners />
      </Container>
      <DetailAnnouncement
        details={state}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            visible: false,
          }))
        }
      />
    </div>
  );
};

export default HomePage;
